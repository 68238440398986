<template>
    <div>
        <app-page-header></app-page-header>

        <v-container grid-list-xl fluid>
            <v-layout row wrap>
                <v-flex xs12 sm12 md12 lg12>
                    <v-card>
                        <v-card-title>
                                <span class="subtitle-1">Fizične vloge </span>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('fizicne-vloge')">
                                    <v-icon>{{ card3 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                        </v-card-title>
                        <v-divider v-show="card3"></v-divider>
                        <v-card-text v-show="card3" v-if="$vuetify.breakpoint.xsOnly">
                            <v-layout row wrap align-center justify-center fill height v-for="(item, index) in applicationItems" :key="index">
                                <v-flex xs12>
                                    <span class="subtitle-1">
                                        <v-icon left>{{ item.icon }}</v-icon> 
                                        {{ item.title }}
                                    </span>   
                                </v-flex>
                                <v-flex xs12>
                                    <v-btn dark color="secondary" small @click="goToRoute(item.routeName)" block outlined>
                                        <v-icon small left>mdi-pencil</v-icon>
                                        Nova vloga
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                        <v-card-text v-show="card3" v-else>
                            <v-list>
                                <v-list-item
                                    v-for="item in applicationItems"
                                    :key="item.title"
                                    @click="goToRoute(item.routeName)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon color="">{{ item.icon }}</v-icon>
                                        </v-list-item-icon>
                                
                                        <v-list-item-content>
                                            <v-list-item-title class="body-2" v-text="item.title"></v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-btn dark color="secondary" small>
                                                <v-icon small left>mdi-pencil</v-icon>
                                                Nova vloga
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>

            <div class="div__divider__15"></div>
            <!-- kriterij iskanja -->
            <v-card>
                <v-card-title>
                    <span class="subtitle-1"><v-icon left>mdi-filter</v-icon> Kriterij iskanja</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="toggleCardContent('card2')">
                        <v-icon>{{ card2 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider v-show="card2"></v-divider>
                <v-card-text v-show="card2">
                    <!-- tip uporabnika -->
                    <v-row class="row--search--filter">
                        <v-col sm="12" md="12" lg="3" cols="12" class="pt-6 pl-5">
                            <span class="body-2">
                                Tip uporabnika
                            </span>
                        </v-col>
                        <v-col sm="12" md="12" lg="9">
                            <v-select
                                v-model="selectedUserTypes"
                                :items="userTypes"
                                label="Izberi tip uporabnika"
                                multiple
                                chips
                                clearable
                                deletable-chips
                                prepend-icon="mdi-bookmark-multiple"
                                item-value="id"
                                item-text="name"
                                return-object
                                dense
                                no-data-text="Ni podatkov za prikaz"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <!-- tip dovolilnice -->
                    <v-row class="row--search--filter">
                        <v-col sm="12" md="12" lg="3" cols="12" class="pt-6 pl-5">
                            <span class="body-2">Tip vloge</span>
                        </v-col>
                        <v-col sm="12" md="12" lg="9">
                            <v-select
                                v-model="selectedPermitTypes"
                                :items="permitTypes"
                                label="Izberi tip vloge"
                                multiple
                                chips
                                clearable
                                deletable-chips
                                prepend-icon="mdi-bookmark-multiple"
                                item-value="id"
                                item-text="name"
                                return-object
                                dense
                                no-data-text="Ni podatkov za prikaz"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <!-- status dovolilnice -->
                    <v-row class="row--search--filter">
                        <v-col sm="12" md="12" lg="3" cols="12" class="pt-6 pl-5">
                            <span class="body-2">Status vloge</span>
                        </v-col>
                        <v-col sm="12" md="12" lg="9">
                            <v-select
                                v-model="selectedApplicationStatuses"
                                :items="applicationStatuses"
                                label="Izberi status vloge"
                                multiple
                                chips
                                clearable
                                deletable-chips
                                prepend-icon="mdi-bookmark-multiple"
                                item-value="id"
                                item-text="name"
                                return-object
                                dense
                                no-data-text="Ni podatkov za prikaz"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <!-- status plačila -->
                    <v-row class="row--search--filter">
                        <v-col sm="12" md="12" lg="3" cols="12" class="pt-6 pl-5">
                            <span class="body-2">Status plačila</span>
                        </v-col>
                        <v-col sm="12" md="12" lg="9">
                            <v-select
                                v-model="selectedPaymentStatuses"
                                :items="paymentStatuses"
                                label="Izberi status plačila"
                                multiple
                                chips
                                clearable
                                deletable-chips
                                prepend-icon="mdi-bookmark-multiple"
                                item-value="id"
                                item-text="name"
                                return-object
                                dense
                                no-data-text="Ni podatkov za prikaz"
                            ></v-select>
                        </v-col>
                    </v-row>

                     <!-- ID kartice -->
                    <v-row class="row--search--filter">
                        <v-col sm="12" md="12" lg="3" cols="12" class="pt-9 pl-5">
                            <span class="body-2">Naziv uporabnika</span>
                        </v-col>
                        <v-col sm="12" md="12" lg="9">
                            <v-text-field
                            v-model="permitHolderName"
                            label="Vpiši naziv uporabnika"
                            prepend-icon="mdi-pencil"
                            @keyup.esc="clearHolderName"
                            @keyup.enter="search"
                            clearable
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <!-- ID kartice -->
                    <v-row class="row--search--filter">
                        <v-col sm="12" md="12" lg="3" cols="12" class="pt-9 pl-5">
                            <span class="body-2">Številka vloge</span>
                        </v-col>
                        <v-col sm="12" md="12" lg="9">
                            <v-text-field
                            v-model="applicationNumber"
                            label="Vpiši številko vloge"
                            prepend-icon="mdi-pencil"
                            @keyup.esc="clearApplicationNumber"
                            @keyup.enter="search"
                            clearable
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider v-show="card2"></v-divider>
                <span v-if="card2">
                    <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                    <v-btn   @click="search()" color="secondary" dark  block outlined style=""><v-icon left>mdi-magnify</v-icon>Prikaz rezultatov&nbsp;</v-btn>
                    <v-btn   @click="clearSearchParameters()" color="warning" dark  block outlined style=""><v-icon left>mdi-close</v-icon>Ponastavi kriterije&nbsp;</v-btn>
                    </v-card-text>
                    <v-card-text v-else>
                        <v-btn  @click="search()" color="secondary" dark text><v-icon left>mdi-magnify</v-icon>Prikaz rezultatov&nbsp;</v-btn>
                        <v-btn  @click="clearSearchParameters()" color="warning" dark text><v-icon left>mdi-close</v-icon>Ponastavi kriterije&nbsp;</v-btn>
                    </v-card-text>
                </span>
            </v-card>


            <br>

            <applications-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></applications-data-table> 
        </v-container>

        <data-table-toggle-columns
        :dataTableId="dataTableSettings.id"
        :columns="dataTableSettings.headers"
        :dataTableColumnsMenuId="dataTableSettings.eventBus.toggleColumnsId"

        ></data-table-toggle-columns>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate } from '@/helpers/utilities'
const ApplicationsDataTable = () => import ('@/components/ApplicationsDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')

export default {
    components: {
        ApplicationsDataTable,
        DataTableToggleColumns
    },

    data: () => ({
        dataTableSettings: {
            id: 'dtApplications',
            title: 'Seznam vlog',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "ID vloge", value: "id", align: "left", visibility: true},
                { text: "Ime in priimek", value: "name", visibility: true},
                { text: "Naslov", value: "address", visibility: true},
                { text: "Poštna št.", value: "postal_code", visibility: true},
                { text: "Kraj", value: "town", visibility: true},
                { text: "Datum vpisa", value: "created_at", visibility: true},
                { text: "Datum urejanja", value: "updated_at", visibility: true},
                { text: "Tip vloge", value: "permit_type", visibility: true},
                { text: "Status vloge", value: "application_status", visibility: true},
                { text: "Status plačila", value: "payment_status", visibility: true},
                { text: "Medij", value: "digital", visibility: true},
                { text: "Upravljanje", width:"10%", value: "action", align: "right", sortable: false, visibility: true}
            ],
            updateInterval: false,
            rowsPerPage: 10,
            endpoint: 'v1/admin/applications',
            editItem: {
                route: "admin_applications_edit",
                routeParameterValue: 'uuid'
            },
            deleteItem: {
                vuexAction: 'DELETE_APPLICATION',
                replaceStrWithColumnValue: 'id',
                confirmationText : "Sta prepričani da želite odstraniti vlogo številka #{str_to_replace}?",
                dialogConfirmSuccessText: 'Vloga s številko #{str_to_replace} je bila uspešno odstranjena.',
                dialogConfirmFailText: 'Pri odstranjevanju vloge s številko #{str_to_replace} je prišlo do napake. Poskusite ponovno.'
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_applications',
                totalItems : {
                    event: ''
                },
                search: 'dt-applications-search',
                toggleColumnsId: 'toggle-applications-list-columns'
            }


        },

        card1: true,
        card2: false,
        card3: true,

        applicationItems: [
            {
                title: 'Parkirna dovolilnica (Blejska kartica)',
                mobile_title: 'Parkirna dovolilnica',
                icon: 'mdi-parking',
                routeName: 'admin_applications_parking_permit'
            }
        ],

        userTypes: [],
        permitTypes: [],
        applicationStatuses: [],
        paymentStatuses: [],
        permitHolderName: '',
        applicationNumber: '',
        

        selectedUserTypes: [],
        selectedPermitTypes: [],
        selectedApplicationStatuses: [],
        selectedPaymentStatuses: []
    }),

    computed: {
        queryParameters() {
            return {
                selectedUserTypes: this.selectedUserTypes.map(item => item.id),
                selectedPermitTypes: this.selectedPermitTypes.map(item => item.id),
                selectedApplicationStatuses: this.selectedApplicationStatuses.map(item => item.id),
                selectedPaymentStatuses: this.selectedPaymentStatuses.map(item => item.id),
                permitHolderName: this.permitHolderName,
                applicationNumber: this.applicationNumber,
            }
        }
    },

    methods: {
        toggleCardContent(card) {
            if(card === 'dovolilnice') {
                this.card1 = !this.card1
            }

            if(card === 'card2') {
                this.card2 = !this.card2
            }

            if(card === 'fizicne-vloge') {
                this.card3 = !this.card3
            }
        },

        goToRoute(routeName) {
            this.$router.push({
                name: routeName
            }).catch();
        },

        clearHolderName() {
            this.permitHolderName = ''
        },

        clearApplicationNumber() {
            this.applicationNumber = ''
        },

        search() {
            EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
        },

        clearSearchParameters() {
            var vm = this
            this.selectedUserTypes = []
            this.selectedApplicationStatusesStatuses = []
            this.selectedPermitTypes = []
            this.selectedPaymentStatuses = []
            this.permitHolderName = ''
            this.applicationNumber = ''
            setTimeout(() => {
                vm.search()
            }, 250)
        },

        getAdminResources() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            this.$store.dispatch('ADMIN_RESOURCES')
            .then(response => {
                //window.console.log(response.data)
                response.data.user_types.forEach(userType => {
                    if(userType.type == 1 || userType.type == 2 || userType.type == 2 || userType.type == 3 || userType.type == 4 || userType.type == 5 || userType.type == 6 || userType.type == 7 || userType.type == 22) {
                        vm.userTypes.push(userType)
                    }
                })

                vm.permitTypes = response.data.permit_types
                vm.applicationStatuses = response.data.application_statuses
                vm.paymentStatuses = response.data.payment_statuses


            })
            .catch(error => {
                window.console.error("### ERROR Permits.vue@getAdminResources ###");
                window.console.log(error)
            })
            .finally(() => {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)
                }, 600)
            })
        }
    },


    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
        this.getAdminResources()
    }
}

</script>

<style>

</style>